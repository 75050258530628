<template>
    <main role="main">
        <div class="container text-center">

            <template v-if="autoSignIn">
                <p v-if="ctrl.message">{{ ctrl.message }}</p>
                <centered-loader v-else></centered-loader>
            </template>
            <template v-else>
                <div class="card auth-card">

                    <div class="card-body">

                        <form @submit.prevent="doAuth" method="post">

                            <div class="header">
                                <img src="../assets/img/logos/logo-color.svg" width="60" height="60" alt="">
                                <h1 class="h3 mb-3">
                                    {{ env.VUE_APP_NAME }}
                                </h1>
                            </div>

                            <div v-if="ctrl.message" class="alert alert-danger" role="alert">
                                {{ ctrl.message }}
                            </div>

                            <div class="mb-3">
                                <input v-model="authUser.email"
                                       required
                                       class="form-control"
                                       type="email"
                                       name="email"
                                       maxlength="80"
                                       placeholder="E-mail">

                                <input v-model="authUser.password"
                                       required
                                       class="form-control"
                                       type="password"
                                       name="password"
                                       maxlength="20"
                                       placeholder="Senha">
                            </div>

                            <template v-if="ctrl.status ==='submitted'">
                                <button disabled class="btn btn-lg btn-primary btn-block" type="submit">
                                    <font-awesome-icon icon="circle-notch" spin/>
                                </button>
                            </template>
                            <template v-else>
                                <button class="btn btn-lg btn-primary btn-block" type="submit">
                                    Entrar
                                </button>
                            </template>

                        </form>
                    </div>
                </div>
            </template>
        </div>
    </main>
</template>

<style scoped>
main {
    position: absolute;
    width: 100%;
    height: 100%;
    min-width: 350px;
    min-height: 406px;
}

main .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.header {
    margin: 20px 0 40px 0;
}

.header img {
    width: 100px;
    height: 100px;
}

.header h1 {
    margin: 15px 0;
    color: #444;
    font-weight: 500;
}

.auth-card {
    width: 100%;
    max-width: 430px;
}

.auth-card input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.auth-card input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.auth-card .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}
</style>

<script>
import AuthMixin from "../mixins/AuthMixin";
import FirebaseHelper from "../helpers/FirebaseHelper";
import EncryptionHelper from "../helpers/EncryptionHelper";
import CenteredLoader from "../components/loaders/CenteredLoader";

export default {
    mixins: [AuthMixin],
    components: {
        CenteredLoader
    },
    data() {
        return {
            authUser: {
                email: '',
                password: '',
                remember: false
            },
            ctrl: {
                status: '',
                message: ''
            }
        }
    },
    methods: {
        redirectIfAuthenticated() {
            if (this._store_getAuthUser && this.common.auth.currentUser) {
                const query = this.$route.query.c
                    ? {c: this.$route.query.c}
                    : null;

                this.$router.push({
                    name: 'home',
                    query: query
                });
            }
        },
        authByQueryString() {
            if (!this.autoSignIn) return;

            const cypher = this.autoSignIn;
            const decrypted = cypher ? EncryptionHelper.decrypt(cypher) : null;
            const parts = String(decrypted).split('|');

            if (parts.length !== 2) {
                this.ctrl.message = 'Invalid parameter value';
                return;
            }

            this.authUser.email = parts[0];
            this.authUser.password = parts[1];
            this.doAuth();
        },
        doAuth() {
            this.ctrl.status = 'submitted';
            this.ctrl.message = '';

            return this._auth_authenticateUser(this.authUser.email, this.authUser.password)
                .then(() => {
                    this.ctrl.message = '';
                    this._store_setAuthUser({
                        email: this.authUser.email,
                        uid: null,
                        displayName: null
                    });
                    this.redirectIfAuthenticated();
                })
                .catch((error) => {
                    this.ctrl.status = '';
                    this.ctrl.message = FirebaseHelper.getTranslatedErrorMessage(error.code) || 'Erro desconhecido';
                });
        }
    },
    computed: {
        autoSignIn() {
            return this.$route.query.c || false;
        }
    },
    mounted() {
        this._auth_setOnAuthStateChanged();
        this.redirectIfAuthenticated();
        this.authByQueryString();
    }
}
</script>

