import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.sessionStorage
});

export const store = new Vuex.Store({
    plugins: [vuexLocal.plugin],
    state: {
        authUser: null,
        authSettings: null,
        authStateChange: null,
        darkMode: false
    },
    mutations: {
        _store_setAuthUser(state, payload) {
            state.authUser = payload;
        },
        _store_setAuthSettings(state, payload) {
            state.authSettings = payload;
        },
        _store_setAuthStateChange(state, payload) {
            if (typeof state.authStateChange === 'function') {
                state.authStateChange();
            }
            state.authStateChange = payload;
        },
        _store_resetStore(state) {
            state.authUser = null;
            state.authSettings = null;
        },
        _store_darkMode(state, enabled) {
            state.darkMode = enabled
        },
    },
    getters: {
        _store_getAuthUser(state) {
            return state.authUser;
        },
        _store_getAuthSettings(state) {
            return state.authSettings;
        },
        _store_getAuthUserProp: (state) => (param) => {
            return state.authUser && state.authUser[param] !== 'undefined'
                ? state.authUser[param]
                : null;
        },
        _store_getAuthSettingsProp: (state) => (param) => {
            return state.authSettings && state.authSettings[param] !== 'undefined'
                ? state.authSettings[param]
                : null;
        },
        _store_getDarkMode(state) {
            return state.darkMode;
        }
    },
    actions: {
        _store_destroyAuth(context) {
            context.commit('_store_resetStore');
        }
    }
});
