import FirebaseAuthCodes from '../lang/FirebaseAuthCodes';

export default class FirebaseHelper {

    static getTranslatedErrorMessage(errorCode) {
        let parts = String(errorCode).split('/');

        if (parts.length < 2) return null;

        let key = parts[0];
        let code = parts[1].replace(/-/g, '_');

        let message = (typeof FirebaseAuthCodes[key] !== 'undefined' &&
            typeof FirebaseAuthCodes[key][code] !== 'undefined')
            ? FirebaseAuthCodes[key][code]
            : null;

        return message;
    }
}
