import Vue from 'vue';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

// icons
import {
    faCircleNotch,
    faClock,
    faEllipsisV,
    faExclamation,
    faExpandAlt,
    faHandHoldingUsd,
    faMoon,
    faRedoAlt,
    faSun,
    faTimes,
    faUserCircle
} from '@fortawesome/free-solid-svg-icons';

import {
    faCircle as farCircle
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faCircleNotch,
    faClock,
    faEllipsisV,
    faExclamation,
    faExpandAlt,
    faHandHoldingUsd,
    faMoon,
    faRedoAlt,
    faSun,
    faTimes,
    faUserCircle
);

library.add(
    farCircle
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
