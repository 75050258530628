import {mapActions, mapGetters, mapMutations} from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

export default {
    data() {
        return {
            common: {
                auth: firebase.auth(),
                database: firebase.database(),
                storage: firebase.storage()
            }
        }
    },
    methods: {
        ...mapActions([
            '_store_destroyAuth'
        ]),
        ...mapMutations([
            '_store_setAuthStateChange'
        ]),
        _common_getAggregate() {
            let uid = this._store_getAuthUserProp('uid');

            if (!uid) throw new Error('uid not set');

            return this.common.database.ref('aggregate').child(uid);
        },
        _common_getSettings() {
            let uid = this._store_getAuthUserProp('uid');

            if (!uid) throw new Error('uid not set');

            return this.common.database.ref('settings').child(uid);
        },
        _common_getUserAsset(filename) {
            let uid = this._store_getAuthUserProp('uid');

            if (!uid) throw new Error('uid not set');

            return this.common.storage.ref(uid).child(filename);
        },
        _common_logoutUser() {
            return this.common.auth.signOut()
                .then(() => this._store_destroyAuth());
        },
        _common_setOnAuthStateChanged() {
            const subscriber = this.common.auth.onAuthStateChanged((user) => {
                if (user) return;
                this._common_logoutUser().then(() => {
                    if (!['sign-in', 'sign-out'].includes(this.$route.name)) {
                        this.$router.push({name: 'sign-in'});
                    }
                });
            });

            this._store_setAuthStateChange(subscriber);
        }
    },
    computed: {
        ...mapGetters([
            '_store_getAuthUser',
            '_store_getAuthUserProp',
            '_store_getAuthSettingsProp'
        ]),
        env() {
            return process.env;
        }
    },
    mounted() {
        this._common_setOnAuthStateChanged();
    }
}
