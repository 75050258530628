import Vue from 'vue'
import Router from 'vue-router'
import {routes} from './routes.js';
import {store} from "@/stores/authStore";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters._store_getAuthUser) {
            const query = to.query.c
                ? {c: to.query.c}
                : null;

            next({
                name: 'sign-in',
                query: query
            });

            return;
        }
    }

    next();
});

export default router;
