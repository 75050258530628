export default {
    auth: {
        app_deleted: 'O banco de dados não foi localizado.',
        expired_action_code: 'O código da ação o ou link expirou.',
        invalid_action_code: 'O código da ação é inválido. Isso pode acontecer se o código estiver malformado ou já tiver sido usado.',
        user_disabled: 'O usuário correspondente à credencial fornecida foi desativado.',
        user_not_found: 'O usuário não correponde à nenhuma credencial.',
        weak_password: 'A senha é muito fraca.',
        email_already_in_use: 'Já existi uma conta com o endereço de email fornecido.',
        invalid_email: 'O endereço de e_mail não é válido.',
        operation_not_allowed: 'O tipo de conta correspondente à esta credencial, ainda não encontra_se ativada.',
        account_exists_with_different_credential: 'E_mail já associado a outra conta.',
        auth_domain_config_required: 'A configuração para autenticação não foi fornecida.',
        credential_already_in_use: 'Já existe uma conta para esta credencial.',
        operation_not_supported_in_this_environment: 'Esta operação não é suportada no ambiente que está sendo executada. Verifique se deve ser http ou https.',
        timeout: 'Excedido o tempo de resposta. O domínio pode não estar autorizado para realizar operações.',
        missing_android_pkg_name: 'Deve ser fornecido um nome de pacote para instalação do aplicativo Android.',
        missing_continue_uri: 'A próxima URL deve ser fornecida na solicitação.',
        missing_ios_bundle_id: 'Deve ser fornecido um nome de pacote para instalação do aplicativo iOS.',
        invalid_continue_uri: 'A próxima URL fornecida na solicitação é inválida.',
        unauthorized_continue_uri: 'O domínio da próxima URL não está na lista de autorizações.',
        invalid_dynamic_link_domain: 'O domínio de link dinâmico fornecido, não está autorizado ou configurado no projeto atual.',
        argument_error: 'Verifique a configuração de link para o aplicativo.',
        invalid_persistence_type: 'O tipo especificado para a persistência dos dados é inválido.',
        unsupported_persistence_type: 'O ambiente atual não suportar o tipo especificado para persistência dos dados.',
        invalid_credential: 'A credencial expirou ou está mal formada.',
        wrong_password: 'Senha incorreta.',
        invalid_verification_code: 'O código de verificação da credencial não é válido.',
        invalid_verification_id: 'O ID de verificação da credencial não é válido.',
        custom_token_mismatch: 'O token está diferente do padrão solicitado.',
        invalid_custom_token: 'O token fornecido não é válido.',
        captcha_check_failed: 'O token de resposta do reCAPTCHA não é válido, expirou ou o domínio não é permitido.',
        invalid_phone_number: 'O número de telefone está em um formato inválido (padrão E.164).',
        missing_phone_number: 'O número de telefone é requerido.',
        quota_exceeded: 'A cota de SMS foi excedida.',
        cancelled_popup_request: 'Somente uma solicitação de janela pop_up é permitida de uma só vez.',
        popup_blocked: 'A janela pop_up foi bloqueado pelo navegador.',
        popup_closed_by_user: 'A janela pop_up foi fechada pelo usuário sem concluir o login no provedor.',
        unauthorized_domain: 'O domínio do aplicativo não está autorizado para realizar operações.',
        invalid_user_token: 'O usuário atual não foi identificado.',
        user_token_expired: 'O token do usuário atual expirou.',
        null_user: 'O usuário atual é nulo.',
        app_not_authorized: 'Aplicação não autorizada para autenticar com a chave informada.',
        invalid_api_key: 'A chave da API fornecida é inválida.',
        network_request_failed: 'Falha de conexão com a rede.',
        requires_recent_login: 'O último horário de acesso do usuário não atende ao limite de segurança.',
        too_many_requests: 'As solicitações foram bloqueadas devido a atividades incomuns. Tente novamente depois que algum tempo.',
        web_storage_unsupported: 'O navegador não suporta armazenamento ou se o usuário desativou este recurso.',
        invalid_claims: 'Os atributos de cadastro personalizado são inválidos.',
        claims_too_large: 'O tamanho da requisição excede o tamanho máximo permitido de 1 Megabyte.',
        id_token_expired: 'O token informado expirou.',
        id_token_revoked: 'O token informado perdeu a validade.',
        invalid_argument: 'Um argumento inválido foi fornecido a um método.',
        invalid_creation_time: 'O horário da criação precisa ser uma data UTC válida.',
        invalid_disabled_field: 'A propriedade para usuário desabilitado é inválida.',
        invalid_display_name: 'O nome do usuário é inválido.',
        invalid_email_verified: 'O e_mail é inválido.',
        invalid_hash_algorithm: 'O algoritmo de HASH não é uma criptografia compatível.',
        invalid_hash_block_size: 'O tamanho do bloco de HASH não é válido.',
        invalid_hash_derived_key_length: 'O tamanho da chave derivada do HASH não é válido.',
        invalid_hash_key: 'A chave de HASH precisa ter um buffer de byte válido.',
        invalid_hash_memory_cost: 'O custo da memória HASH não é válido.',
        invalid_hash_parallelization: 'O carregamento em paralelo do HASH não é válido.',
        invalid_hash_rounds: 'O arredondamento de HASH não é válido.',
        invalid_hash_salt_separator: 'O campo do separador de SALT do algoritmo de geração de HASH precisa ser um buffer de byte válido.',
        invalid_id_token: 'O código do token informado não é válido.',
        invalid_last_sign_in_time: 'O último horário de login precisa ser uma data UTC válida.',
        invalid_page_token: 'A próxima URL fornecida na solicitação é inválida.',
        invalid_password: 'A senha é inválida, precisa ter pelo menos 6 caracteres.',
        invalid_password_hash: 'O HASH da senha não é válida.',
        invalid_password_salt: 'O SALT da senha não é válido.',
        invalid_photo_url: 'A URL da foto de usuário é inválido.',
        invalid_provider_id: 'O identificador de provedor não é compatível.',
        invalid_session_cookie_duration: 'A duração do COOKIE da sessão precisa ser um número válido em milissegundos, entre 5 minutos e 2 semanas.',
        invalid_uid: 'O identificador fornecido deve ter no máximo 128 caracteres.',
        invalid_user_import: 'O registro do usuário a ser importado não é válido.',
        invalid_provider_data: 'O provedor de dados não é válido.',
        maximum_user_count_exceeded: 'O número máximo permitido de usuários a serem importados foi excedido.',
        missing_hash_algorithm: 'É necessário fornecer o algoritmo de geração de HASH e seus parâmetros para importar usuários.',
        missing_uid: 'Um identificador é necessário para a operação atual.',
        reserved_claims: 'Uma ou mais propriedades personalizadas fornecidas usaram palavras reservadas.',
        session_cookie_revoked: 'O COOKIE da sessão perdeu a validade.',
        uid_alread_exists: 'O indentificador fornecido já está em uso.',
        email_already_exists: 'O e_mail fornecido já está em uso.',
        phone_number_already_exists: 'O telefone fornecido já está em uso.',
        project_not_found: 'Nenhum projeto foi encontrado.',
        insufficient_permission: 'A credencial utilizada não tem permissão para acessar o recurso solicitado.',
        internal_error: 'O servidor de autenticação encontrou um erro inesperado ao tentar processar a solicitação.'
    }
};
