import firebase from "firebase/app";
import "firebase/auth";
import CommonMixin from "./CommonMixin";
import {mapMutations} from "vuex";

export default {
    mixins: [CommonMixin],
    methods: {
        ...mapMutations([
            '_store_setAuthStateChange',
            '_store_setAuthUser'
        ]),
        _auth_authenticateUser(email, password) {
            return this.common.auth
                .setPersistence(firebase.auth.Auth.Persistence.SESSION)
                .then(() => this.common.auth.signInWithEmailAndPassword(email, password))

        },
        _auth_setOnAuthStateChanged() {
            const subscriber = this.common.auth.onAuthStateChanged((user) => {
                if (!user) return;
                this._store_setAuthUser({
                    email: user.email,
                    uid: user.uid,
                    displayName: user.displayName
                });
            });

            this._store_setAuthStateChange(subscriber);
        }
    }
}
