import SignInPage from '../pages/SignInPage';

const HomePage = () => import('../pages/HomePage.vue');
const SignOutPage = () => import('../pages/SignOutPage.vue');

const BiofotonicaCampaign = () => import('../pages/campaigns/BiofotonicaCampaign');
const HotsiteCampaign = () => import('../pages/campaigns/HotsiteCampaign');
const StaffCampaign = () => import('../pages/campaigns/StaffCampaign');
const SuperMarketCampaign = () => import('../pages/campaigns/SuperMarketCampaign');
const TicketCampaign = () => import('../pages/campaigns/TicketCampaign');
const UniversityCampaign = () => import('../pages/campaigns/UniversityCampaign');

export const routes = [
    {path: '/', meta: {requiresAuth: true}, name: 'home', component: HomePage},

    {path: '/biofotonica', meta: {requiresAuth: true}, name: 'biofotonica', component: BiofotonicaCampaign},
    {path: '/hotsite', meta: {requiresAuth: true}, name: 'hotsite', component: HotsiteCampaign},
    {path: '/staff', meta: {requiresAuth: true}, name: 'staff', component: StaffCampaign},
    {path: '/supermarket', meta: {requiresAuth: true}, name: 'supermarket', component: SuperMarketCampaign},
    {path: '/ticket', meta: {requiresAuth: true}, name: 'ticket', component: TicketCampaign},
    {path: '/university', meta: {requiresAuth: true}, name: 'university', component: UniversityCampaign},

    {path: '/signin', meta: {requiresAuth: false}, name: 'sign-in', component: SignInPage},
    {path: '/signout', meta: {requiresAuth: false}, name: 'sign-out', component: SignOutPage}
];
