import Vue from 'vue';
import App from './App.vue';
import {store} from './stores/authStore.js';
import router from './router/router.js';
import './bootstrap/firebase';
import './bootstrap/layout';
import './bootstrap/fortawesome-icons'
import Notifications from 'vue-notification';

Vue.config.productionTip = false;

Vue.use(Notifications);

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app');
