import AES from 'crypto-js/aes';
import encUtf8 from 'crypto-js/enc-utf8';

export default class EncryptionHelper {

    static get secret() {
        return process.env.VUE_APP_FIREBASE_CRYPTO_SECRET;
    }

    static encrypt(text) {
        return AES.encrypt(text, this.secret).toString();
    }

    static decrypt(cipher) {
        return AES.decrypt(cipher, this.secret).toString(encUtf8);
    }
}
